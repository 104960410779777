import * as React from 'react';
import { Highlight, Snippet } from 'react-instantsearch';
import { Link } from 'gatsby';


import styled from '@emotion/styled';


const HitContainer = styled.div`
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px; 
  padding-top: 10px; 
`;

export const PageHit = (clickHandler) => ({ hit }) => {
  return (
    <HitContainer className="hit-container">
      <Link to={hit.slug} onClick={clickHandler}>
        <div>
          <Highlight attribute="title" hit={hit} highlightedTagName="mark" />
        </div>
        <Snippet attribute="excerpt" hit={hit} highlightedTagName="mark" />
      </Link>
    </HitContainer>
  );
}