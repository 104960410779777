const config = {
  gatsby: {
    pathPrefix: '/',
    siteTitle: `Seeka developers`,
    siteUrl: 'https://developers.seeka.co',
    gaTrackingId: null,
    trailingSlash: false,
    siteUrl: `https://developers.seeka.co`,
    siteAuthor: `@seeka-labs`,
    siteImage: `/banner.png`,
    siteLanguage: `en`,
  },
  header: {
    logo: '/seeka-logo-white.svg',
    logoLink: 'https://developers.seeka.co',
    title:
      "<a href='https://developers.seeka.co'><img class='img-responsive' src='/seeka-logo-white.svg' alt='Seeka logo' /></a>",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: 'Seeka HQ', link: 'https://seeka.co' }, { text: 'Support', link: 'https://support.seeka.co' }],
    search: {
      enabled: true,
      indexName: 'developer-docs-gatsby',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    links: [],
    frontLine: true,
    ignoreIndex: false,
    title: "",
  },
  siteMetadata: {
    title: `Seeka developers`,
    description: `Developer and marketer documentation for interacting with Seeka and Converge platforms`,
    themeColor: `#722ED1`,
    basePath: `/`,
    ogImage: null,
    docsLocation: '',
    favicon: '/favicon.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
