import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';

export function useDebounce(cb, delay) {
    // ...
    const inputsRef = useRef({ cb, delay }); // mutable ref like with useThrottle
    const isMounted = useIsMounted();
    useEffect(() => { inputsRef.current = { cb, delay }; }); //also track cur. delay
    return useCallback(
        debounce((...args) => {
            // Debounce is an async callback. Cancel it, if in the meanwhile
            // (1) component has been unmounted (see isMounted in snippet)
            // (2) delay has changed
            if (inputsRef.current.delay === delay && isMounted())
                inputsRef.current.cb(...args);
        }, delay
        ),
        [delay, debounce]
    );
}

function useIsMounted() {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return () => isMountedRef.current;
}