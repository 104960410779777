import React, { useEffect, useMemo, useState } from 'react';

export const MultiLanguageBlock = ({ children }) => {
    const languageLogos = {
        'nodejs-axios': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg',
        'nodejs-fetch': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg',
        'php': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg',
        'csharp-httpclient': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg',
        'csharp-restsharp': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg',
        'go-http-client': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/go/go-original.svg',
    };

    const languages = useMemo(() => {
        return React.Children.map(children, (child) => {
            const langCode = child.props['lang-code']?.toString();
            return {
                code: langCode,
                name: child.props['lang-name']?.toString(),
                logo: languageLogos[langCode] || 'https://via.placeholder.com/50', // Fallback to placeholder if no logo found
            }
        });
    }, [React.Children]);

    const isSsr = typeof window === 'undefined';
    const langStorageKey = 'seeka.docs.lang';
    const storageLang = isSsr ? undefined : window.localStorage.getItem(langStorageKey);

    const defaultLang = languages[0].code;
    const [selectedLang, setSelectedLang] = useState(defaultLang);

    useEffect(() => {
        if (isSsr || !storageLang) return;

        const defaultLang = storageLang && languages.find(e => e.code === storageLang);
        if (defaultLang) {
            setSelectedLang(defaultLang.code);
        }
    }, [isSsr]);

    const setLang = (langCode) => {
        setSelectedLang(langCode);
        !isSsr && window.localStorage.setItem(langStorageKey, langCode);
    }

    return (
        <div>
            <div style={{ marginBottom: '10px', display: 'flex', gap: '15px' }}>
                {languages.map((lang) => (
                    <div
                        key={lang.code}
                        onClick={() => setLang(lang.code)}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '5px',
                            border: selectedLang === lang.code ? '2px solid #663399' : '2px solid transparent',
                            borderRadius: '15px',
                            transition: 'border 0.3s',
                        }}
                    >
                        {/* Display the logo */}
                        <img
                            src={lang.logo}
                            alt={lang.name}
                            style={{ width: '40px', height: '40px' }}
                        />
                        {/* Display the language name next to the logo */}
                        <span>{lang.name}</span>
                    </div>
                ))}
            </div>
            <div key={selectedLang}>
                {React.Children.map(children, (child) => {
                    return child.props['lang-code'] === selectedLang ? child : null;
                })}
            </div>
        </div>
    );
};