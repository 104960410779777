import React, { useState, useEffect, createRef } from 'react';
import {
  InstantSearch,
  Index,
  Hits,
  Configure,
  useInstantSearch,
  useSearchBox,
} from 'react-instantsearch';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import config from '../../../config.js';

import styled from '@emotion/styled';
import { Search } from '@styled-icons/fa-solid/Search';
import CustomSearchBox from './input.js';
import * as hitComps from './hitComps.js';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import { useDebounce } from '../../utils/useDebounce.js';

const SearchIcon = styled(Search)`
  width: 1em;
  pointer-events: none;
`;

const HitsWrapper = styled.div`
  display: ${props => (props.show ? `grid` : `none`)}; 
`;

const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
`;

const Results = () => {
  const { uiState } = useInstantSearch();

  return <></>

  // return (
  //   ({ searching, searchState: state, searchResults: res }) =>
  //     (searching && `Searching...`) || (res && res.nbHits === 0 && `No results for '${uiState.query}'`)
  // )
}

/// TODO: This click outside listener isnt working 
const useClickOutside = (r, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`, `mouseup`];

  const detectClickOutside = event => {
    // console.log('hereee useClickOutside', r.current, event.target)
    return r && r.current && !r.current.contains(event.target) && handler()
  };

  useEffect(() => {
    for (const event of events) document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events) document.removeEventListener(event, detectClickOutside);
    };
  });
};

const searchClient = algoliasearch(
  config.header.search.algoliaAppId,
  config.header.search.algoliaSearchKey
);

export default function SearchComponent({ indices, collapse, hitsAsGrid }) {
  const ref = createRef();

  const converge = useSeekaConverge();

  const keywordSearchEventFire = useDebounce((uiState) => {
    const searchText = uiState[indices[0].name]?.query
    if (searchText) {
      converge.track.keywordSearch({
        searchText: searchText
      })
    }
  }, 1500)

  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (focus) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [focus])

  // useClickOutside(ref, () => setFocus(false));
  return (
    <div
      ref={ref}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onStateChange={({ uiState, setUiState }) => {
          setUiState(uiState);
          const searchText = uiState[indices[0].name]?.query
          if (!searchText) {
            setFocus(false)
          }
          if (uiState) {
            keywordSearchEventFire(uiState)
          }
        }}
        root={{ Root }}
      >
        <SearchInput setFocus={setFocus} />
        <HitsWrapperContainer indices={indices} hitsAsGrid={hitsAsGrid} focus={focus} setFocus={setFocus} />
        <Configure hitsPerPage={10} />
      </InstantSearch>
    </div>
  );
}

const SearchInput = ({ setFocus }) => {
  const searchProps = useSearchBox();

  return (
    <CustomSearchBox {...searchProps} setFocus={setFocus} />)
}

const HitsWrapperContainer = ({ indices, hitsAsGrid, setFocus, focus }) => {
  const { query } = useSearchBox();
  // console.log('heree query', query)
  const displayResult = query.length > 0 && focus ? 'showResults' : 'hideResults';

  return (<HitsWrapper
    className={'hitWrapper ' + displayResult}
    show={query.length > 0 && focus}
    asGrid={hitsAsGrid}
  >
    {indices.map(({ name, title, hitComp, type }) => {
      return (
        <Index key={name} indexName={name}>
          <Results />
          <Hits hitComponent={hitComps[hitComp](() => setFocus(false))} />
        </Index>
      );
    })}
  </HitsWrapper>)
}