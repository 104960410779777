module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/@seeka-labs/gatsby-plugin-seeka/gatsby-browser.js'),
      options: {"plugins":[],"publicKey":"sdkr_Q2ZESjhPWkNZdzdQRGdSQXU4VWZjV0c3TmdIcFlxTklka2ZJNzIyMjBrZF9iQkZNZUxGOU8xNUNhZUhOT1lacHBLM24zN042X2hPRVctSktkWTVWODRfWUhpUHgtNkJaZ3ZwMDkwaDRkYXRQS3BkaU1aQlE2eS1heVFkWDRfTGpsRk1TZHJiT0dxYlVQdEN6eFlyTld2aXdhZFNyTGZwSkFSaFJCR2tUOWtYZTlyTVM","org":"6b38b47b35f158df06823a0c0c4c6003","id":"0c1426cac8cbdea4dd023a0c0c4c7d52","hosts":[{"hostname":"b467.seeka.co"}]},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
