import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// import Link from './link';
import config from '../../config';
import { Sidebar, ListItem } from './styles/Sidebar';

const SidebarLayout = ({ location }) => {
  const { allMdx } = useStaticQuery(graphql`
  query {
    allMdx {
      edges {
        node {
          fields {
            slug
          }
          tableOfContents
        }
      }
    }
  }
`)

  // console.log('heree allMdx', allMdx)

  // let navItems = [];

  let finalNavItems;

  if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
    const navItems = allMdx.edges.map((item, index) => {
      let innerItems;

      if (item !== undefined) {
        if (
          item.node.fields.slug === location.pathname ||
          config.gatsby.pathPrefix + item.node.fields.slug === location.pathname
        ) {
          // console.log('heree tableOfContents', item.node.tableOfContents.items)
          if (item.node.tableOfContents.items) {
            innerItems = item.node.tableOfContents.items.flatMap((innerItem, index) => {
              const itemId = innerItem.title
                ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                : '#';

              if (!innerItem.items) {
                return [(
                  <ListItem key={index} to={`#${itemId}`} level={1}>
                    {innerItem.title}
                  </ListItem>
                )];
              }
              else {
                return [(
                  <ListItem key={index} to={`#${itemId}`} level={1}>
                    {innerItem.title}
                  </ListItem>
                ), ...innerItem.items.map((item, childIndex) => {
                  const childItemId = item.title
                    ? item.title.replace(/\s+/g, '').toLowerCase()
                    : '#';
                  return (
                    <ListItem key={index + '_' + childIndex} to={`#${childItemId}`} level={2}>
                      {item.title?.replace('()', '')}
                    </ListItem>
                  )
                })];
              }

            });
          }
        }
      }
      if (innerItems) {
        finalNavItems = innerItems;
      }
    });
  }

  if (finalNavItems && finalNavItems.length) {
    return (
      <Sidebar>
        <ul className={'rightSideBarUL'}>
          <li className={'rightSideTitle'}>CONTENTS</li>
          {finalNavItems}
        </ul>
      </Sidebar>
    );
  } else {
    return (
      <></>
    );
  }
};

export default SidebarLayout;
