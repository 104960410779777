import React from 'react';
import { MultiLanguageBlock } from './multiLanguageBlock';
import CodeBlock from './codeBlock';
import { generatePhpData, jsObjectToGoMap, generatePhpHeaders, jsObjectToCSharpAnonymous } from '../../utils/exampleHttpRequestUtils';

export const ExampleHttpRequest = ({ functionName, dataName, url, method, headers, body, headersComment, expectedResponseCode, functionDescription, replacementTokens }) => {
    functionName = functionName || 'makeRequest';
    dataName = dataName || 'data';

    expectedResponseCode = expectedResponseCode || 200;
    functionDescription = functionDescription || 'make the request';

    const phpData = generatePhpData(body);
    const phpHeaders = generatePhpHeaders(headers);
    const goData = jsObjectToGoMap(body, 4);
    const cSharpData = jsObjectToCSharpAnonymous(body, 3);

    return (
        <>
            {replacementTokens && replacementTokens.length > 0 && (
                <div style={{ marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px solid #EDEDED' }}>
                    <div style={{ marginBottom: '10px' }}>The below tokens in the code example should be <strong>replaced with your own values</strong>.</div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token</th>
                                <th>Required</th>
                                <th>Description</th>
                                <th>Example</th>
                            </tr>
                        </thead>
                        <tbody>
                            {replacementTokens.map((token, index) => (
                                <tr key={index}>
                                    <td>
                                        <code>{token.token}</code>
                                    </td>
                                    <td>{token.required ? 'Yes' : ''}</td>
                                    <td>{token.description}</td>
                                    <td>{token.example}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <MultiLanguageBlock>
                <div lang-code="nodejs-axios" lang-name="Node.js - Axios">
                    <CodeBlock codeLang='js' codeContent={`
const axios = require('axios');

async function ${functionName}(data) {
    try {
        // ${headersComment || 'Headers'}
        const headers = ${JSON.stringify(headers, null, 12)};

        const response = await axios({
            url: '${url}',
            method: '${method}',
            data: data,
            headers: headers
        });

        if(response.status !== ${expectedResponseCode}) {
            console.error('Unexpected response code when trying to ${functionDescription}', response.status);
        }
        else {
            console.log('Completed ${functionDescription}');
        }
    } 
    catch (error) {
        console.error('Error when attempting to ${functionDescription}', error);
    }
}

// Create ${dataName}
const ${dataName} = ${JSON.stringify(body, null, 2)};

// Send ${dataName}
await ${functionName}(${dataName});
`}>
                    </CodeBlock>
                </div>



                <div lang-code="nodejs-fetch" lang-name="Node.js - Fetch">
                    <CodeBlock codeLang='js' codeContent={`
const fetch = require('node-fetch');

async function ${functionName}(data) {
    try {
        // ${headersComment || 'Headers'}
        const headers = ${JSON.stringify(headers, null, 12)};

        const response = await fetch('${url}', {
            method: '${method}',
            headers: headers,
            body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if(response.status !== ${expectedResponseCode}) {
            console.error('Unexpected response code when trying to ${functionDescription}', {status: response.status, responseData});
        }
        else {
            console.log('Completed ${functionDescription}');
        }
    } 
    catch (error) {
        console.error('Error when attempting to ${functionDescription}', error);
    }
}

// Create ${dataName}
const ${dataName} = ${JSON.stringify(body, null, 2)};

// Send ${dataName}
await ${functionName}(${dataName});
`}>
                    </CodeBlock>
                </div>





                <div lang-code="php" lang-name="PHP - cURL">
                    <CodeBlock codeLang='php' codeContent={`
<?php

function ${functionName}($data) {
    $url = '${url}';
    $method = '${method}';

    // ${headersComment || 'Headers'}
    $headers = [
        ${phpHeaders}
    ];

    $ch = curl_init();

    curl_setopt($ch, CURLOPT_URL, $url);
    curl_setopt($ch, CURLOPT_CUSTOMREQUEST, $method);

    curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

    ${method === 'GET' ? '' : 'curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));'}
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_USERAGENT, '<integration name>');

    $response = curl_exec($ch);

    // Check for cURL errors
    if (curl_errno($ch)) {
        echo 'Error ${functionDescription} ' . curl_error($ch);
    } else {
        $responseCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
        if($responseCode !== ${expectedResponseCode}) {
            echo 'Unexpected response code when trying to ${functionDescription}: ' . $responseCode . PHP_EOL;
            echo 'Response data: ' . $response . PHP_EOL;
        }
        else {
            echo 'Completed ${functionDescription}' . PHP_EOL;
        }
    }

    curl_close($ch);
}

// Create ${dataName}
$${dataName} = ${phpData};

// Send ${dataName}
${functionName}($${dataName});

?>
`}>
                    </CodeBlock>
                </div>




                <div lang-code="csharp-httpclient" lang-name="C# - HttpClient">
                    <CodeBlock codeLang='csharp' codeContent={`
using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;
using Newtonsoft.Json;

public class Program
{
    public static async Task ${functionName.charAt(0).toUpperCase() + functionName.slice(1)}(object data)
    {
        try
        {
            // ${headersComment || 'Headers'}
            var client = new HttpClient();
            var json = JsonConvert.SerializeObject(data, Formatting.Indented);
            var content = new StringContent(json, Encoding.UTF8, "application/json");

            ${Object.keys(headers).map(key => `client.DefaultRequestHeaders.Add("${key}", "${headers[key]}");`).join('\n            ')}

            var response = await client.SendAsync(new HttpRequestMessage
            {
                Method = new HttpMethod("${method}"),
                RequestUri = new Uri("${url}"),
                Content = content
            });

            if ((int)response.StatusCode != ${expectedResponseCode})
            {
                Console.WriteLine("Unexpected response code when trying to ${functionDescription}: " + (int)response.StatusCode);
            }
            else
            {
                Console.WriteLine("Completed ${functionDescription}");
            }
        }
        catch (Exception ex)
        {
            Console.WriteLine("Error when attempting to ${functionDescription}: " + ex.Message);
        }
    }

    public static async Task Main(string[] args)
    {
        // Create ${dataName}
        var ${dataName} = new { 
${cSharpData}
        };

        // Send ${dataName}
        await ${functionName.charAt(0).toUpperCase() + functionName.slice(1)}(${dataName});
    }
}
`}>
                    </CodeBlock>
                </div>




                <div lang-code="csharp-restsharp" lang-name="C# - RestSharp">
                    <CodeBlock codeLang='csharp' codeContent={`
using System;
using System.Threading.Tasks;
using Newtonsoft.Json;
using RestSharp;

public class Program
{
    public static async Task ${functionName.charAt(0).toUpperCase() + functionName.slice(1)}(object data)
    {
        try
        {
            // ${headersComment || 'Headers'}
            var client = new RestClient("${url}");
            var request = new RestRequest();
            request.Method = Method.${method.charAt(0).toUpperCase() + method.slice(1).toLowerCase()};

            request.AddJsonBody(data);

            ${Object.keys(headers).map(key => `request.AddHeader("${key}", "${headers[key]}");`).join('\n            ')}

            var response = await client.ExecuteAsync(request);

            if ((int)response.StatusCode != ${expectedResponseCode})
            {
                Console.WriteLine("Unexpected response code when trying to ${functionDescription}: " + (int)response.StatusCode);
            }
            else
            {
                Console.WriteLine("Completed ${functionDescription}");
            }
        }
        catch (Exception ex)
        {
            Console.WriteLine("Error when attempting to ${functionDescription}: " + ex.Message);
        }
    }

    public static async Task Main(string[] args)
    {
        // Create ${dataName}
        var ${dataName} = new { 
${cSharpData}
        };

        // Send ${dataName}
        await ${functionName.charAt(0).toUpperCase() + functionName.slice(1)}(${dataName});
    }
}
`}>
                    </CodeBlock>
                </div>




                <div lang-code="go-http-client" lang-name="Go - HTTP Client">
                    <CodeBlock codeLang='go' codeContent={`
package main

import (
	"bytes"
	"encoding/json"
	"fmt"
	"io/ioutil"
	"log"
	"net/http"
)

func ${functionName}(${dataName} interface{}) {
	// Convert the data object to JSON
	jsonData, err := json.Marshal(${dataName})
	if err != nil {
		log.Fatalf("Error marshalling data: %v", err)
	}

	// Create the request
	client := &http.Client{}
	req, err := http.NewRequest("${method}", "${url}", bytes.NewBuffer(jsonData))
	if err != nil {
		log.Fatalf("Error creating request: %v", err)
	}

	// ${headersComment || 'Headers'}
	${Object.keys(headers).map(key => `req.Header.Set("${key}", "${headers[key]}")`).join('\n    ')}

	// Send the request
	resp, err := client.Do(req)
	if err != nil {
		log.Fatalf("Error sending request: %v", err)
	}
	defer resp.Body.Close()

	// Check response code
	if resp.StatusCode != ${expectedResponseCode} {
		bodyBytes, _ := ioutil.ReadAll(resp.Body)
		bodyString := string(bodyBytes)
		log.Printf("Unexpected response code when trying to ${functionDescription}: %d - %s", resp.StatusCode, bodyString)
	} else {
		log.Printf("Completed ${functionDescription}")
	}
}

func main() {
	// Create ${dataName}
	${dataName} := ${goData}

	// Send ${dataName}
	${functionName}(${dataName})
}
`}>
                    </CodeBlock>
                </div>




            </MultiLanguageBlock>
        </>
    );
};

