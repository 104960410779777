import * as React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import GitHubButton from 'react-github-btn';
import Link from './link';

import config from '../../config.js';
import SearchComponent from './search';

import Sidebar from './sidebar';

const help = require('./images/help.svg');

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

// const StyledBgDiv = styled('div')`
//   height: 60px
//   background-color: #f8f8f8;
//   position: relative;
//   display: none;
//   background: ${(props) => (props.isDarkThemeActive ? '#001932' : undefined)};

//   @media (max-width: 767px) {
//     display: block;
//   }
// `;

const Header = ({ location, isDarkThemeActive, toggleActiveTheme }) => {


  const [isMobileNavActive, setIsMobileNavActive] = React.useState(false);
  function toggleMobile() {
    var x = document.getElementById('navbar');

    if (!isMobileNavActive) {
      x.className += ' responsive';
      setIsMobileNavActive(true)
    } else {
      x.className = 'topnav';
      setIsMobileNavActive(false)
    }
  }

  const onLinkClick = () => {
    // console.log('heree onLinkClick')
    setIsMobileNavActive(false)
  }

  React.useEffect(() => {
    if (isMobileNavActive) {
      document.getElementById('contentWrapper').style.display = 'none';
    }
    else {
      document.getElementById('contentWrapper').style.display = 'flex';
    }
  }, [isMobileNavActive])

  const data = useStaticQuery(graphql`
  query headerTitleQuery2 {
    site {
      siteMetadata {
        headerTitle
        githubUrl
        helpUrl
        tweetText
        logo {
          link
          image
        }
        headerLinks {
          link
          text
        }
      }
    }
  }
`)
  // console.log('heree dat2222a', data)

  const logoImg = require('./images/logo.svg');

  const twitter = require('./images/twitter.svg');

  const discordBrandsBlock = require('./images/discord-brands-block.svg');

  const twitterBrandsBlock = require('./images/twitter-brands-block.svg');

  const {
    site: {
      siteMetadata: { headerTitle, githubUrl, helpUrl, tweetText, logo, headerLinks },
    },
  } = data;

  const finalLogoLink = logo.link !== '' ? logo.link : 'https://developers.seeka.co';

  return (
    <div className={'navBarWrapper'}>
      <nav className={'navBarDefault'}>
        <div className={'navBarHeader'}>
          <Link to={finalLogoLink} className={'navBarBrand'}>
            <img
              className={'img-responsive displayInline'}
              src={logo.image !== '' ? logo.image : logoImg}
              alt={'logo'}
            />
          </Link>
          {/* <div
            className={'headerTitle displayInline'}
            dangerouslySetInnerHTML={{ __html: headerTitle }}
          /> */}
        </div>
        {config.header.social ? (
          <ul
            className="socialWrapper visibleMobileView"
            dangerouslySetInnerHTML={{ __html: config.header.social }}
          ></ul>
        ) : null}
        {isSearchEnabled && !isMobileNavActive ? (
          <div className={'searchWrapper navBarUL'}>
            <SearchComponent collapse={true} indices={searchIndices} />
          </div>
        ) : null}
        <div>
          <span
            onClick={toggleMobile}
            className={'navBarToggle'}
            onKeyDown={toggleMobile}
            role="button"
            tabIndex={0}
          >
            <span className={'iconBar'}></span>
            <span className={'iconBar'}></span>
            <span className={'iconBar'}></span>
          </span>
        </div>
        <div id="navbar" className={'topnav'}>
          <div className={'visibleMobile'}>
            <Sidebar location={location} onLinkClick={onLinkClick} />
          </div>
          <ul className={'navBarUL navBarNav navBarULRight hiddenMobile'}>
            {headerLinks.map((link, key) => {
              if (link.link !== '' && link.text !== '') {
                return (
                  <li key={key}>
                    <a
                      className="sidebarLink"
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: link.text }}
                    />
                  </li>
                );
              }
            })}
            {helpUrl !== '' ? (
              <li>
                <a href={helpUrl}>
                  <img src={help} alt={'Help icon'} />
                </a>
              </li>
            ) : null}

            {tweetText !== '' ? (
              <li>
                <a
                  href={'https://twitter.com/intent/tweet?&text=' + tweetText}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className={'shareIcon'} src={twitter} alt={'Twitter'} />
                </a>
              </li>
            ) : null}
            {tweetText !== '' || githubUrl !== '' ? (
              <li className="divider hiddenMobile"></li>
            ) : null}
            {config.header.social ? (
              <li className={'hiddenMobile'}>
                <ul
                  className="socialWrapper"
                  dangerouslySetInnerHTML={{ __html: config.header.social }}
                ></ul>
              </li>
            ) : null}
            {githubUrl !== '' ? (
              <li className={'githubBtn'}>
                <GitHubButton
                  href={githubUrl}
                  data-show-count="true"
                  aria-label="Star on GitHub"
                >
                  Star
                </GitHubButton>
              </li>
            ) : null}
            {/* <li>
            <DarkModeSwitch
              isDarkThemeActive={isDarkThemeActive}
              toggleActiveTheme={toggleActiveTheme}
            />
          </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
