import React, { useState } from 'react';
import config from '../../../config';
import TreeNode from './treeNode';

import menu from '../../../menu.yaml'

const Tree = ({ edges, onLinkClick, location }) => {
  // console.log('hereee Tree', menu)

  const toggle = url => {
    setCollapsed({
      ...collapsed,
      [url]: !collapsed[url],
    });
  };

  const defaultCollapsed = {};

  if (location.pathname && location.pathname != '/') {
    var split = location.pathname.split('/');
    var toExpand = [];
    var lastUrl = '';
    split.forEach((item, index) => {
      if (!item) return;

      var thisUrl = lastUrl + '/' + item;
      lastUrl = thisUrl;
      toExpand.push(thisUrl);
    });
    toExpand.forEach(url => {
      defaultCollapsed[url] = true;
    });
  }

  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  return (
    menu.map((item, index) => (
      <TreeNode
        key={index}
        onLinkClick={onLinkClick}
        className={`${config.sidebar.frontLine ? 'showFrontLine' : 'hideFrontLine'} firstLevel`}
        setCollapsed={toggle}
        collapsed={collapsed}
        location={location}
        edges={edges}
        {...item}
      />))
  );
};

export default Tree;
