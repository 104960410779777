export function jsObjectToGoMap(obj, indentation = 0) {
    let indent = ' '.repeat(indentation); // Indentation for formatting
    let goMap = 'map[string]interface{}{\n';

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];
            goMap += `${indent}    "${key}": `;

            if (typeof value === 'string') {
                goMap += `"${value}"`;
            } else if (typeof value === 'number' || typeof value === 'boolean') {
                goMap += `${value}`;
            } else if (Array.isArray(value)) {
                goMap += '[\n';
                value.forEach((v, i) => {
                    goMap += indent + '        '; // Adjust indentation for array values
                    if (typeof v === 'string') {
                        goMap += `"${v}"`;
                    } else if (typeof v === 'object') {
                        goMap += jsObjectToGoMap(v, indentation + 8); // Handle nested objects within arrays
                    } else {
                        goMap += v;
                    }
                    if (i !== value.length - 1) goMap += ',';
                    goMap += '\n';
                });
                goMap += indent + '    ]';
            } else if (typeof value === 'object') {
                goMap += jsObjectToGoMap(value, indentation + 4); // Recursively handle nested objects
            }

            goMap += `,\n`;
        }
    }

    goMap += `${indent}}`;

    return goMap;
}


export const generatePhpHeaders = (headers) => Object.entries(headers)
    .map(([key, value]) => `'${key}: ${value}'`)
    .join(",\n        ");

export function generatePhpData(dataObj, indentLevel = 0) {
    const indent = '    '.repeat(indentLevel);

    function convertValue(value, indentLevel) {
        const innerIndent = '    '.repeat(indentLevel + 1);
        if (typeof value === 'string') {
            return `'${value}'`;
        } else if (typeof value === 'number' || typeof value === 'boolean') {
            return value;
        } else if (Array.isArray(value)) {
            const arrayItems = value
                .map(item => convertValue(item, indentLevel + 1))
                .join(`,\n${innerIndent}`);
            return `[\n${innerIndent}${arrayItems}\n${indent}]`;
        } else if (typeof value === 'object' && value !== null) {
            return generatePhpData(value, indentLevel + 1);
        } else {
            return 'null';
        }
    }

    const entries = Object.entries(dataObj).map(([key, value]) => {
        return `${indent}'${key}' => ${convertValue(value, indentLevel)}`;
    });

    return `[\n${entries.join(`,\n`)}\n${indent}]`;
}

export function jsObjectToCSharpAnonymous(obj, indentLevel = 1) {
    const indent = '    '.repeat(indentLevel);
    const innerIndent = '    '.repeat(indentLevel + 1);
    const deeperIndent = '    '.repeat(indentLevel + 2);

    const properties = Object.entries(obj).map(([key, value]) => {
        if (Array.isArray(value)) {
            if (typeof value[0] === 'string' || typeof value[0] === 'number') {
                const arrayItems = value.map(item => `"${item}"`).join(`,\n${deeperIndent}`);
                return `${indent}${key} = new[]\n${indent}{\n${deeperIndent}${arrayItems}\n${indent}}`;
            } else {
                const arrayItems = value.map(item => `new\n${innerIndent}{\n${jsObjectToCSharpAnonymous(item, indentLevel + 2)}\n${innerIndent}}`).join(`,\n${innerIndent}`);
                return `${indent}${key} = new[]\n${indent}{\n${innerIndent}${arrayItems}\n${indent}}`;
            }
        } else if (typeof value === 'object' && value !== null) {
            return `${indent}${key} = new\n${indent}{\n${jsObjectToCSharpAnonymous(value, indentLevel + 1)}\n${indent}}`;
        } else if (typeof value === 'string') {
            return `${indent}${key} = "${value}"`;
        } else {
            return `${indent}${key} = ${value}`;
        }
    });

    return properties.join(',\n');
}