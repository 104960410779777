const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#fff',
    heading: '#000',
    text: '#1d1d1d',
    preFormattedText: 'rgb(245, 247, 249)',
    link: '#722ED1',
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#1B0A3E',
    heading: '#fff',
    text: '#fff',
    preFormattedText: '#000',
    link: '#722ED1',
  },
};

export { lightTheme, darkTheme };
