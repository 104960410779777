import * as React from 'react';
import styled from '@emotion/styled';
import Sidebar from './sidebar/index.js';
import RightSidebar from './rightSidebar.js';
import config from '../../config.js';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import Header from './Header.js';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};

  .sideBarUL li a {
    color: white;
  }

  .sideBarUL .item > a:hover {
    background-color: #722ED1;
    color: #fff !important;

    /* background: #F8F8F8 */
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 30px;
  padding-top: 2rem;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    background: ${({ theme }) => theme.colors.background};
  }
`;

const MaxWidth = styled('div')`
  width: 100%;
  max-width: 1024px;
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  background-image: linear-gradient(to right, #19083B, #371c66);
`;

const RightSideBarWidth = styled('div')`
  width: 224px;
`;

const Layout = ({ children, location }) => {
  const converge = useSeekaConverge();

  const lastLocationRef = React.useRef('');

  React.useEffect(() => {
    if (lastLocationRef.current === location.key) return;
    lastLocationRef.current = location.key;
    converge.track.viewPage();
  }, [location])

  return (
    <>
      <Header
        location={location}
      />
      <div>
        <Wrapper id='contentWrapper'>
          <LeftSideBarWidth className={'hiddenMobile'}>
            <Sidebar location={location} />
          </LeftSideBarWidth>
          {config.sidebar.title ? (
            <div
              className={'sidebarTitle sideBarShow'}
              dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
            />
          ) : null}
          <Content>
            <MaxWidth>{children}</MaxWidth>
          </Content>
          <RightSideBarWidth className={'hiddenMobile'}>
            <RightSidebar location={location} />
          </RightSideBarWidth>
        </Wrapper>
      </div>
    </>
  );
}

export default Layout;