import * as React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';
import isAbsoluteUrl from 'is-absolute-url';

const TreeNode = ({ className = '', setCollapsed, collapsed, collapsable, url, items, title, onLinkClick, edges, location }) => {
  // const isCollapsed = true;

  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items?.length > 0;

  const node = edges.find(edge => edge.node.fields.slug === url)?.node;
  if (!node) {
    console.warn('No node found for url', url)
  }
  const navTitle = title || node?.fields?.title;
  const isCollapsed = collapsable && !collapsed[url];

  const active =
    location && (location.pathname === url || location.pathname === (url + '/') || location.pathname === config.gatsby.pathPrefix + url || location.pathname === '' && url === '') //|| (url && url.startsWith(location.pathname));

  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {navTitle && (
        <Link to={url} target={isAbsoluteUrl(url) ? "_blank" : undefined} onClick={() => {
          if (onLinkClick) onLinkClick();
          if (collapsable) {
            collapse();
          }
        }}>
          {navTitle}
          {collapsable && navTitle && hasChildren ? (
            <button aria-label="collapse" className="collapser">
              {isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : null}
        </Link>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              onLinkClick={onLinkClick}
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              edges={edges}
              title={title}
              location={location}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
