import * as React from 'react';
import Layout from './src/components/layout';

//import "prism-themes/themes/prism-dracula.css"
import "prism-themes/themes/prism-vsc-dark-plus.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"
// import "prismjs/plugins/command-line/prism-command-line.css"
import "./src/components/theme/prism.customtheme.css"

import { MDXProvider } from '@mdx-js/react';
import ThemeProvider from './src/components/theme/themeProvider.js';
import mdxComponents from './src/components/mdxComponents/index.js';

import { Prism } from 'prism-react-renderer';

// Prism languages

var windowPrism = window.Prism;
window.Prism = Prism;
import { refractor } from 'refractor'
import phpLang from 'refractor/lang/php.js';
import csharpLang from 'refractor/lang/csharp.js';

// Register the languages with Refractor
refractor.register(phpLang);
refractor.register(csharpLang);


// import { Prism } from 'prism-react-renderer'
// import phpLang from 'refractor/lang/php.js';
// try {
//   phpLang(Prism);
// }
// catch (e) {
//   console.error(e);
// }
// import csharpLang from 'refractor/lang/csharp.js';
// try {
//   csharpLang(Prism);
// }
// catch (e) {
//   console.error(e);
// }

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `The Seeka docs have been updated. ` +
    `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <MDXProvider components={mdxComponents}>
        {element}
      </MDXProvider>
    </ThemeProvider>
  )
}